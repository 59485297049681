import { Textstore } from '../../../../../../shared/lang-dialog/textstore.model';
import { User, UserInfo } from '../../../../../../shared/user/user.model';
import { BaseModel } from '../../../../../../core/base.model';
import {
  GoalProcessMetadata,
  KPIProcessMetadata,
  ProcessInstanceData,
  ProcessMetadata,
  ProcessProcessMetadata,
} from './process-diagram.metadata';
import { Characteristic } from 'app/core/components/characteristic/characteristic.model';
import { StepHeaderData } from '../../shared/step-requirements/model/step-requirement-data.model';
import { Progress } from '../../../result/inspection-result/instance-progress.model';
import { DocumentInfo } from '../../../file-manager/document.model';
import { SampleRequiredPropertyKeyType } from '../../shared/step-requirements/model/sample-requirement-property-key-type';

export type ProcessUIEvents = 'start_instance' | 'toggle_delete' | 'delete_forever';

export enum ProcessDiagramType {
  PROCESS = 'PROCESS',
  GOAL = 'GOAL',
  KPI = 'KPI',
  INSPECTION = 'INSPECTION',
}

export enum ProcessDiagramFlow {
  ONE_INSTANCE_FLOW = 'ONE_INSTANCE_FLOW', //-> goals, kpi
  MULTI_INSTANCE_FLOW = 'MULTI_INSTANCE_FLOW', //-> process
  COPY_INSTANCE_FLOW = 'COPY_INSTANCE_FLOW', //-> inspections
}

export enum ProcessDiagramStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  PLANNED = 'PLANNED',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  DONE = 'DONE',
  RELEASED = 'RELEASED',
  REVOKED = 'REVOKED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum StepStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export enum CharacteristicStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  OK = 'OK',
  NOK = 'NOK',
  EXCEPTION = 'EXCEPTION',
}

export enum StepType {
  DEFAULT = 'DEFAULT',
  INSPECTION = 'INSPECTION',
  IDENT_INSPECTION = 'IDENT_INSPECTION',
  INSPECTION_DECISION = 'INSPECTION_DECISION',
}

export interface DiagramStep {
  id: string;
  headerData?: StepHeaderData;
  textStore?: Textstore;
  type?: StepType;
  characteristics?: Characteristic[];
  documentList?: DocumentInfo[];
  sampleData?: Sample;
  status?: StepStatus;
  isOk?: boolean;
}

export interface StepInstanceData {
  sampleNumber: number;
}

export class DiagramStepRequirement {
  constructor(
    public id: string,
    // public type?: RequirementType,
    public children: DiagramStepRequirement[] = [], // public data: { [key: string]: RequirementDataTypes } = {},
  ) {}
}

export interface Sample {
  partId: string;
  processInstanceId: string;
  stepId: string;
  sampleNumber: number;
  progress: Progress;
  status: StepStatus;
  batchNumber: string;
  isOk: boolean;
  sampleRequirements: Record<SampleRequiredPropertyKeyType, string>;
}

export abstract class ProcessDiagram extends BaseModel {
  public abstract type: ProcessDiagramType;

  constructor(
    public id?: string,
    public index?: string,
    public name = new Textstore(),
    public description = new Textstore(),
    public version?: string,
    public status = 'NEW',
    public diagramFlow?: ProcessDiagramFlow,
    public responsible?: UserInfo,
    public steps: DiagramStep[] = [],
    public deleted = false,
    public parentId?: string,
    public isParent = false,
    public lastChanged?: Date,
    public metadata?: ProcessMetadata,
    public processInstanceData?: ProcessInstanceData,
    public documentList: DocumentInfo[] = [],
    public lastEditedBy?: UserInfo,
    public lastEditedOn?: Date,
    public createdProcessBy?: UserInfo,
    public createdOn?: Date,
    public numbering?: boolean,
    public productionStepId?: string,
    public enterCause?: boolean,
    plantId?: string,
  ) {
    super(plantId);
  }
}
export class ProcessProcessDiagram extends ProcessDiagram {
  readonly type = ProcessDiagramType.PROCESS;
  readonly diagramFlow = ProcessDiagramFlow.MULTI_INSTANCE_FLOW;
  constructor(
    public id?: string,
    public name = new Textstore(),
    public description = new Textstore(),
    public version?: string,
    public status = 'NEW',

    public responsible?: User,
    public steps: DiagramStep[] = [],
    public deleted = false,
    public parentId?: string,
    public isParent = false,
    public lastChanged?: Date,
    public metadata?: ProcessProcessMetadata,
    public validityStart?: Date,
    public validityEnd?: Date,
    plantId?: string,
  ) {
    super(plantId);
  }
}

export class GoalProcessDiagram extends ProcessDiagram {
  readonly type = ProcessDiagramType.GOAL;
  readonly diagramFlow = ProcessDiagramFlow.ONE_INSTANCE_FLOW;
  constructor(
    public id?: string,
    public name = new Textstore(),
    public description = new Textstore(),
    public version?: string,
    public status = 'NEW',
    public responsible?: User,
    public steps: DiagramStep[] = [],
    public deleted = false,
    public parentId?: string,
    public isParent = false,
    public lastChanged?: Date,
    public metadata?: GoalProcessMetadata,
    public validityStart?: Date,
    public validityEnd?: Date,
    plantId?: string,
  ) {
    super(plantId);
  }
}

export class KPIProcessDiagram extends ProcessDiagram {
  readonly type = ProcessDiagramType.KPI;
  readonly diagramFlow = ProcessDiagramFlow.ONE_INSTANCE_FLOW;
  constructor(
    public id?: string,
    public name = new Textstore(),
    public description = new Textstore(),
    public version?: string,
    public status = 'NEW',
    public responsible?: User,
    public steps: DiagramStep[] = [],
    public deleted = false,
    public parentId?: string,
    public isParent = false,
    public lastChanged?: Date,
    public metadata?: KPIProcessMetadata,
    public validityStart?: Date,
    public validityEnd?: Date,
    plantId?: string,
  ) {
    super(plantId);
  }
}

export interface StepRenameDTO {
  diagramId: string;
  stepId: string;
  stepName: Textstore;
}
