/* eslint-disable max-lines-per-function */
import {
  DASHActions,
  GET_DASHBOARD_LIST_SUCCESS,
  GET_DASHBOARD_LIST_ERROR,
  UPDATE_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD_ERROR,
  SET_CONFIG_MODE,
  TRY_REMOVE_WIDGET,
  TRY_ADD_WIDGET,
  TRY_UPDATE_GRID_POSITION,
  UNLOAD_DASHBOARD_LIST,
  TRY_UPDATE_WIDGET_DETAILS,
  DESELECT_DASHBOARD,
  LOAD_VIEW_ONLY_MODE,
  TRY_UPDATE_WIDGET_COLUMN_SIZES,
  TRY_GET_DASHBOARD_CHILDREN_LIST,
  ON_DASHBOARD_ROUTE_CHANGE,
  LOAD_STATIC_DASHBOARD,
  TRY_SAVE_SEARCH_CRITERIA,
} from './dashboard.actions';

import { Dashboard } from '../../board/models/board.model';
import { predefinedDashboards } from './dashboard-execution.data';

export interface State {
  dashboardList: Dashboard[];
  selectedDashboardId: string;
  loading: boolean;
  configMode: boolean;
  viewOnly: boolean;
  parentId: string;
  parentDashboard: Dashboard;
}

const initialState: State = {
  dashboardList: [],
  selectedDashboardId: null,
  loading: false,
  configMode: false,
  viewOnly: true,
  parentId: null,
  parentDashboard: null,
};

export function dashboardReducer(state: State = initialState, action: DASHActions): State {
  switch (action.type) {
    case LOAD_STATIC_DASHBOARD:
      return {
        ...state,
        dashboardList: [predefinedDashboards.find(({ id }) => id === action.payload)],
        selectedDashboardId: predefinedDashboards.find(({ id }) => id === action.payload)?.id,
      };
    case LOAD_VIEW_ONLY_MODE:
      return {
        ...state,
        viewOnly: action.payload,
      };
    case TRY_SAVE_SEARCH_CRITERIA:
    case TRY_UPDATE_WIDGET_DETAILS:
      const widgetUpdateDashboard = state.dashboardList.find((dash) => dash.id === state.selectedDashboardId);

      const widgetIndex = widgetUpdateDashboard.widgets.findIndex(
        (wid) => wid.instanceId === action.payload.instanceId,
      );
      const configUpdateDashboard = {
        ...widgetUpdateDashboard,
        widgets: [
          ...widgetUpdateDashboard.widgets.slice(0, widgetIndex),
          {
            ...widgetUpdateDashboard.widgets[widgetIndex],
            values: {
              ...widgetUpdateDashboard.widgets[widgetIndex].values,
              ...action.payload.config,
            },
          },
          ...widgetUpdateDashboard.widgets.slice(widgetIndex + 1),
        ],
      };

      return {
        ...state,
        dashboardList: state.dashboardList.map((board) =>
          configUpdateDashboard.id === board.id ? configUpdateDashboard : board,
        ),
      };
    case TRY_UPDATE_WIDGET_COLUMN_SIZES:
      const columnUpdateDashboard = state.dashboardList.find((dash) => dash.id === state.selectedDashboardId);

      const widgetIndx = columnUpdateDashboard.widgets.findIndex((wid) => wid.instanceId === action.payload.instanceId);

      const columnSizesUpdateDashboard = {
        ...columnUpdateDashboard,
        widgets: [
          ...columnUpdateDashboard.widgets.slice(0, widgetIndx),
          {
            ...columnUpdateDashboard.widgets[widgetIndx],
            values: {
              ...columnUpdateDashboard.widgets[widgetIndx].values,
              ...action.payload.config,
            },
          },
          ...columnUpdateDashboard.widgets.slice(widgetIndx + 1),
        ],
      };

      return {
        ...state,
        dashboardList: state.dashboardList.map((board) =>
          columnSizesUpdateDashboard.id === board.id ? columnSizesUpdateDashboard : board,
        ),
      };
    case TRY_ADD_WIDGET:
      const addWidgetDashboard = state.dashboardList.find((dash) => dash.id === state.selectedDashboardId);

      const widgetData = action.payload;
      const updatedBoard = state.selectedDashboardId && {
        ...addWidgetDashboard,
        widgets: [...addWidgetDashboard.widgets, ...widgetData],
      };

      return {
        ...state,
        loading: true,
        dashboardList: state.dashboardList.map((board) => (updatedBoard.id === board.id ? updatedBoard : board)),
      };
    case TRY_REMOVE_WIDGET:
      const removeWidgetDashboard = state.dashboardList.find((dash) => dash.id === state.selectedDashboardId);

      const widgetId = action.payload;
      const widgetRemovedBoard = state.selectedDashboardId && {
        ...removeWidgetDashboard,
        widgets: removeWidgetDashboard.widgets.filter((widget) => widget.instanceId !== widgetId),
      };

      return {
        ...state,
        loading: true,
        dashboardList: state.dashboardList.map((board) =>
          widgetRemovedBoard?.id === board.id ? widgetRemovedBoard : board,
        ),
      };
    case TRY_UPDATE_GRID_POSITION:
      const gridPostionUpdateDashboard = state.dashboardList.find((dash) => dash.id === state.selectedDashboardId);

      const gridsterPositions = action.payload;

      const updatedDashboard = state.selectedDashboardId && {
        ...gridPostionUpdateDashboard,
        widgets: gridPostionUpdateDashboard.widgets.map((widget, i) => ({
          ...widget,
          gridsterData: gridsterPositions.find(({ instanceId }) => widget.instanceId === instanceId),
        })),
      };

      return {
        ...state,
        loading: true,
        dashboardList: state.dashboardList.map((board) =>
          updatedDashboard?.id === board.id ? updatedDashboard : board,
        ),
      };
    case DESELECT_DASHBOARD:
      return {
        ...state,
        selectedDashboardId: null,
      };
    case SET_CONFIG_MODE:
      return {
        ...state,
        configMode: !state.viewOnly && action.payload,
      };
    case ON_DASHBOARD_ROUTE_CHANGE:
      return {
        ...state,
        selectedDashboardId: action.payload,
      };
    case TRY_GET_DASHBOARD_CHILDREN_LIST:
      return {
        ...state,
        dashboardList: initialState.dashboardList,
        parentDashboard: initialState.parentDashboard,
        parentId: action.payload,
        loading: true,
      };
    case GET_DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        dashboardList: [...action.payload.dashboards],
        parentDashboard: action.payload.parentDashboard,
        parentId: action.payload?.parentDashboard.id,
        loading: false,
      };
    case UPDATE_DASHBOARD_SUCCESS:
    case UPDATE_DASHBOARD_ERROR:
    case UPDATE_DASHBOARD_ERROR:
    case GET_DASHBOARD_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UNLOAD_DASHBOARD_LIST:
      return {
        ...state,
        ...initialState,
        dashboardList: [],
      };
    default:
      return state;
  }
}

export const getDashboardList = (state: State) => state.dashboardList;

export const getSelectedDashboardId = (state: State) => state.selectedDashboardId;

export const getParentDashboardId = (state: State) => state.parentId;

export const getLoading = (state: State) => state.loading;

export const getConfigMode = (state: State) => state.configMode;

export const getViewOnlyMode = (state: State) => state.viewOnly;

export const getParentDashboard = (state: State) => state.parentDashboard;
