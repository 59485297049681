import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UniqueUserEmailValidator } from 'app/core/validators/unique-user-email.validator';
import { User } from 'app/shared';
import { Role } from '../../roles/role.model';
import { UserManagerFormData } from './user-manager-form.model';

@Injectable()
export class UserManagerDetailsService {
  constructor(
    private fb: UntypedFormBuilder,
    private emailValidator: UniqueUserEmailValidator,
  ) {}

  buildUserForm(user: User, roleOptions: Role[] = []): UntypedFormGroup {
    return this.fb.group({
      basic: this.buildBasicUserDataForm(user),
      additional: this.buildAdditionalUserDataForm(user),
      contact: this.buildContactUserDataForm(user),
      roles: this.buildRolesForm(user.roles || [], roleOptions),
    });
  }

  mapUserFormValueToModel(user: User, formData: UserManagerFormData, roleOptions?: Role[]): User {
    // Basic form data
    const email = formData.basic.email;
    const firstName = formData.basic.firstName;
    const lastName = formData.basic.lastName;
    const activated = formData.basic.activated;

    // Additional form data
    const langKey = formData.additional.langKey;
    const location = formData.additional.location;
    const organisation = formData.additional.organisation;

    // Contact form data
    const contactData = {
      emailData: Object.entries(formData.contact.emails).map(([id, email]) => ({
        id,
        ...email,
      })),
      phoneData: Object.entries(formData.contact.phones).map(([id, phone]) => ({
        id,
        ...phone,
      })),
      addressData: Object.entries(formData.contact.addresses).map(([id, address]) => ({ id, ...address })),
      socialMediaData: Object.entries(formData.contact.socialMedia).map(([id, socialMedia]) => ({
        id,
        ...socialMedia,
      })),
    };

    let roles = user.roles;
    if (roleOptions) {
      roles = this.getSelectedRoles(formData, roleOptions);
    }

    return {
      ...user,
      email,
      firstName,
      lastName,
      activated,
      langKey,
      location,
      organisation,
      contactData,
      roles,
    };
  }

  private buildBasicUserDataForm(user: User): UntypedFormGroup {
    const basicGroup = this.fb.group({
      email: [
        user?.email || '',
        Validators.compose([Validators.required, Validators.email]),
        Validators.composeAsync([this.emailValidator.validate(user.id)]),
      ],
      firstName: [user?.firstName || '', Validators.required],
      lastName: [user?.lastName || '', Validators.required],
      activated: [user.activated],
    });

    return basicGroup;
  }

  private buildAdditionalUserDataForm(user: User): UntypedFormGroup {
    return this.fb.group({
      langKey: [user?.langKey || '', Validators.required],
      location: user?.location,
      organisation: [user?.organisation],
    });
  }

  private buildContactUserDataForm(user: User): UntypedFormGroup {
    return this.fb.group({
      emails: this.buildEmailsForm(user),
      phones: this.buildPhonesForm(user),
      addresses: this.buildAdressesForm(user),
      socialMedia: this.buildSocialMediaForm(user),
    });
  }

  private buildRolesForm(ownedByUser: Role[], roleOptions: Role[]): UntypedFormGroup {
    const controlsConfig = {};

    for (const role of roleOptions) {
      const ownedRoleIndex = ownedByUser.findIndex((ownedRole: Role) => ownedRole.id === role.id);

      const isRoleOwned = ownedRoleIndex >= 0;

      controlsConfig[role.id] = isRoleOwned;
    }

    const rolesForm = this.fb.group(controlsConfig);
    return rolesForm;
  }

  private buildEmailsForm(user: User): UntypedFormGroup {
    return this.fb.group(
      Object.fromEntries(
        (user.contactData?.emailData || []).map((emailData) => [
          emailData.id,
          this.fb.group({
            email: [emailData.email, Validators.required],
            isActive: emailData.isActive,
          }),
        ]),
      ),
    );
  }

  private buildPhonesForm(user: User): UntypedFormGroup {
    return this.fb.group(
      Object.fromEntries(
        (user.contactData?.phoneData || []).map((phoneData) => [
          phoneData.id,
          this.fb.group({
            number: [phoneData.number, Validators.required],
            type: phoneData.type,
          }),
        ]),
      ),
    );
  }

  private buildAdressesForm(user: User): UntypedFormGroup {
    return this.fb.group(
      Object.fromEntries(
        (user.contactData?.addressData || []).map((addressData) => [
          addressData.id,
          this.fb.group({
            address: [addressData.address, Validators.required],
          }),
        ]),
      ),
    );
  }

  private buildSocialMediaForm(user: User): UntypedFormGroup {
    return this.fb.group(
      Object.fromEntries(
        (user.contactData?.socialMediaData || []).map((socialMedia) => [
          socialMedia.id,
          this.fb.group({ url: [socialMedia.url, Validators.required] }),
        ]),
      ),
    );
  }

  private getSelectedRoles(formValue: any, roleOptions: Role[]) {
    const selectedRoles: Role[] = [];

    for (const role of roleOptions) {
      if (formValue['roles'][role.id]) {
        selectedRoles.push({ ...role });
      }
    }

    return selectedRoles;
  }
}
