/* eslint-disable max-lines-per-function */
import {
  DASHSEActions,
  LOAD_STEP_BY_ID_SUCCESS,
  LOAD_STEP_BY_ID_ERROR,
  CLEAR_DASHBOARD_HEADER_SEARCH_CRITERIA,
  LOAD_CRITERIA_SEARCH_DATA_SUCCESS,
  UPDATE_INPUTTED_DOCUMENTS_FOR_PARAMETER_DOCUMENTS_WIDGET,
  UPDATE_INPUTTED_DOCUMENTS_FOR_PARAMETER_INPUT_CHARACTERISTIC,
  NAVIGATED_TO_DASHBOARD,
  CLEAN_SEARCH_STATE,
  SELECT_FOLLOWING_CHARACTERISTIC,
  FINISH_CRITERIA_UPDATE,
  GET_CHARACTERISTIC_HISTORY_SUCCESS,
  GET_CHARACTERISTIC_HISTORY_ERROR,
  TRY_GET_CHARACTERISTIC_HISTORY,
  SELECT_LOADED_STEP_CHARACTERISTIC_SUCCESS,
  LOAD_NEXT_STEP_SUCCESS,
  TRY_LOAD_STEP_BY_ID,
  TRY_SUBMIT_CHAR_VALUE_ZERO_MESSAGE,
  ZERO_MESSAGE_CHAR_VALUE_SUBMIT_ERROR,
  SELECT_CHARACTERISTIC,
  SELECT_NEXT_CHARACTERISTIC,
  SELECT_PREVIOUS_CHARACTERISTIC,
  RECEIVED_SOCKET_PROGRESS,
  CLEAR_INDEX_IN_SAMPLE_SELECTION,
  GET_MY_BRIDGES_SUCCESS,
  ADD_BRIDGE_SUCCESS,
  REMOVE_BRIDGE_SUCCESS,
  UPDATE_CHARACTERISTIC_DS_SUCCESS,
  SELECT_PROCESS_INSTANCE_WITH_STEP_SUCCESS,
  RECIEVED_CHARACTERISTIC_HISTORY_ENTRY,
  SKIP_LOADING_CHARACTERISTIC_HISTORY,
  RECEIVED_CHAR_HISTORY_ENTRY_SAME_CHAR,
  UPDATED_CHARACTERISTIC_HISTORY,
  INSTANCE_IS_ALREADY_LOCKED_BY_ANOTHER_USER,
  UNLOCK_PROCESS_INSTANCE_SUCCESS,
  NO_STEP_ID_TO_LOAD,
  STEP_FOR_LOAD_NOT_IN_DIAGRAM,
  RELEASE_PROCESS_INSTANCE_LOCK_SUCCESS,
  ZERO_MESSAGE_CHAR_VALUE_SUBMIT_SUCCESS,
  NO_NEXT_STEP_TO_LOAD,
  SEND_NEXT_SAMPLE_MESSAGE_SUCCESS,
  ZERO_MESSAGE_CHAR_VALUE_CHAR_IS_SAME,
  ZERO_MESSAGE_CHAR_VALUE_ALL_VALUES_ENTERED,
  SEND_STEP_FINISH_MESSAGE_SUCCESS,
  UPDATE_EXCEPTION_ALL_VALUES_ENTERED,
  GET_CHARACTERISTIC_SAMPLE_DATA_SUCCESS,
  TRY_GET_CHARACTERISTIC_SAMPLE_DATA,
  TRY_CHANGE_SELECTED_SAMPLE,
  CHANGE_SELECTED_SAMPLE_SUCCESS,
  RESET_CRITERIA_ON_ROUTE,
  TRY_LOAD_PROCESS_INSTANCE_WITH_STEP,
  CLEAR_SYNC_STATUS_ERROR_SUCCESS,
  SELECT_HISTORY_INDEX_IN_SAMPLE_SUCCESS,
  SET_IDENTITY_CHECK_STATUS_SUCCESS,
  DECIDE_ALL_CHARACTERISTICS_IN_BLOCK_SUCCESS,
  UPDATE_ACTION_CATALOG_FOR_CHARACTERISTIC_IN_SAMPLE_SUCCESS,
} from './board-search-store.actions';
import { ProcessInstance } from 'app/main/content/apps/result/process-result/process-instance.model';
import { DocumentStore } from 'app/main/content/apps/file-manager/document.model';
import {
  CharacteristicStatus,
  Sample,
  StepStatus,
  StepType,
} from 'app/main/content/apps/designers/process/model/process-diagram.model';
import {
  CharacteristicSample,
  InspectionCharacteristicMetadata,
} from 'app/core/components/characteristic/characteristic.model';
import { CharacteristicHistory, CharacteristicValueDT } from '../../models/message.model';
import { Catalog } from 'app/main/content/apps/catalog/catalog/catalog.model';

export interface State {
  processInstance: ProcessInstance;
  stepId: string;
  characteristicId: string;
  selectedIndexInSample: number;
  lastSubmittedDocuments: DocumentStore[];
  characteristicHistory: CharacteristicHistory[];
  characteristicHistoryLoading: boolean;
  valueMessageSubmitting: boolean;
  myBridges: Catalog[];
  processInstanceLoading: boolean;
  selectedCharacteristicSampleData: CharacteristicSample[];
  instanceCharacteristicSampleData: Record<string, CharacteristicSample>;
  selectedSampleNumber: number;
  stepSampleData: Sample[];
}

const initialState: State = {
  processInstance: null,
  stepId: null,
  characteristicId: null,
  lastSubmittedDocuments: [],
  characteristicHistory: [],
  characteristicHistoryLoading: false,
  valueMessageSubmitting: false,
  selectedIndexInSample: null,
  myBridges: [],
  processInstanceLoading: false,
  selectedCharacteristicSampleData: [],
  instanceCharacteristicSampleData: {},
  selectedSampleNumber: null,
  stepSampleData: [],
};

export function dashboardSearchReducer(state: State = initialState, action: DASHSEActions): State {
  switch (action.type) {
    case SET_IDENTITY_CHECK_STATUS_SUCCESS:
      if (state.processInstance?.id !== action.payload.instanceId) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        processInstance: {
          ...state.processInstance,
          diagramInstance: state.processInstance.diagramInstance && {
            ...state.processInstance.diagramInstance,
            steps: state.processInstance.diagramInstance.steps.map((step) => {
              if (step.type !== StepType.IDENT_INSPECTION) return step;

              return {
                ...step,
                isOk: action.payload.value,
                status: StepStatus.DONE,
              };
            }),
          },
        },
      };
    case TRY_CHANGE_SELECTED_SAMPLE:
      return {
        ...state,
        selectedSampleNumber: action.payload,
        selectedIndexInSample: initialState.selectedIndexInSample,
      };
    case SELECT_HISTORY_INDEX_IN_SAMPLE_SUCCESS:
      return {
        ...state,
        instanceCharacteristicSampleData: Object.fromEntries(
          action.payload.progress.charData.map((csd) => [csd.characteristicId, csd]),
        ),
        selectedIndexInSample: action.payload.index,
        selectedSampleNumber: action.payload.progress.sample.sampleNumber,
      };
    case DECIDE_ALL_CHARACTERISTICS_IN_BLOCK_SUCCESS:
      const decideAllCharData = state.selectedCharacteristicSampleData.find(
        ({ sampleNumber }) => sampleNumber === action.payload.sample.sampleNumber,
      );

      return {
        ...state,
        instanceCharacteristicSampleData: Object.fromEntries(
          action.payload.charData.map((csd) => [csd.characteristicId, csd]),
        ),
        selectedIndexInSample:
          decideAllCharData?.status === CharacteristicStatus.OK ||
          decideAllCharData?.status === CharacteristicStatus.NOK
            ? decideAllCharData.indexInSample
            : initialState.selectedIndexInSample,
      };
    case CHANGE_SELECTED_SAMPLE_SUCCESS:
      const changeSampleCharData = state.selectedCharacteristicSampleData.find(
        ({ sampleNumber }) => sampleNumber === action.payload.sample.sampleNumber,
      );
      const selectedCharacteristic = state.processInstance?.diagramInstance?.steps
        .flatMap((step) => step.characteristics)
        .find(({ id }) => id === state.characteristicId);

      return {
        ...state,
        characteristicId:
          selectedCharacteristic?.inspectionInterval?.everyNthSample &&
          (state.selectedSampleNumber + 1) % selectedCharacteristic?.inspectionInterval?.everyNthSample !== 0
            ? null
            : state.characteristicId,
        instanceCharacteristicSampleData: Object.fromEntries(
          action.payload.charData.map((csd) => [csd.characteristicId, csd]),
        ),
        selectedIndexInSample:
          changeSampleCharData?.status === CharacteristicStatus.OK ||
          changeSampleCharData?.status === CharacteristicStatus.NOK
            ? changeSampleCharData.indexInSample
            : initialState.selectedIndexInSample,
      };
    case TRY_GET_CHARACTERISTIC_SAMPLE_DATA:
      return {
        ...state,
        selectedCharacteristicSampleData: initialState.selectedCharacteristicSampleData,
      };
    case GET_CHARACTERISTIC_SAMPLE_DATA_SUCCESS:
      const stepSampleNumber = Math.max(...state.stepSampleData?.map(({ sampleNumber }) => sampleNumber));

      const sampleCharData = action.payload.find(
        ({ sampleNumber }) => sampleNumber === (state.selectedSampleNumber ?? stepSampleNumber ?? 1),
      );

      return {
        ...state,
        selectedCharacteristicSampleData: action.payload,
        selectedIndexInSample:
          sampleCharData?.status === CharacteristicStatus.OK || sampleCharData?.status === CharacteristicStatus.NOK
            ? sampleCharData.indexInSample
            : initialState.selectedIndexInSample,
      };
    case SELECT_PROCESS_INSTANCE_WITH_STEP_SUCCESS:
      return {
        ...state,
        stepSampleData: action.payload.sampleData ?? [],
        instanceCharacteristicSampleData: Object.fromEntries(
          action.payload.instanceProgress.stepData
            .flatMap((sd) => sd.charData)
            .map((csd) => [csd.characteristicId, csd]),
        ),
        processInstance: action.payload.instance && {
          ...action.payload.instance,
          status: action.payload.instanceProgress.instanceStatus,
          sync: action.payload.instanceProgress.sync,
          diagramInstance: action.payload.instance.diagramInstance && {
            ...action.payload.instance.diagramInstance,
            processInstanceData: {
              ...action.payload.instance.diagramInstance.processInstanceData,
              instanceLock: action.payload.instanceProgress.instanceLock,
            },
          },
        },
        stepId: action.payload.stepId,
        characteristicId: action.payload.characteristicId,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        characteristicHistory: initialState.characteristicHistory,
        selectedCharacteristicSampleData: initialState.selectedCharacteristicSampleData,
        selectedSampleNumber: initialState.selectedSampleNumber,
        characteristicHistoryLoading: true,
        selectedIndexInSample: initialState.selectedIndexInSample,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case GET_MY_BRIDGES_SUCCESS:
      return {
        ...state,
        myBridges: action.payload,
      };
    case ADD_BRIDGE_SUCCESS:
      return {
        ...state,
        myBridges: [...state.myBridges, action.payload],
      };
    case REMOVE_BRIDGE_SUCCESS:
      return {
        ...state,
        myBridges: state.myBridges.filter(({ id }) => id !== action.payload),
      };
    case CLEAR_INDEX_IN_SAMPLE_SELECTION:
      return {
        ...state,
        selectedIndexInSample: initialState.selectedIndexInSample,
      };
    // case SELECT_HISTORY_INDEX_IN_SAMPLE:
    // case SELECT_STATISTICS_INDEX_IN_SAMPLE:
    //     return {
    //         ...state,
    //         selectedIndexInSample: action.payload,
    //     };
    case SELECT_CHARACTERISTIC:
      const selectedCharStep = state.processInstance.diagramInstance.steps.find(({ characteristics }) =>
        characteristics.some(({ id }) => id === action.payload),
      );

      return {
        ...state,
        characteristicId: action.payload,
        stepId: selectedCharStep?.id,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        characteristicHistory: initialState.characteristicHistory,
        selectedCharacteristicSampleData: initialState.selectedCharacteristicSampleData,
        characteristicHistoryLoading: true,
        selectedIndexInSample: initialState.selectedIndexInSample,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case TRY_LOAD_PROCESS_INSTANCE_WITH_STEP:
      return {
        ...state,
        processInstanceLoading: true,
      };
    case UPDATE_INPUTTED_DOCUMENTS_FOR_PARAMETER_INPUT_CHARACTERISTIC:
    case UPDATE_INPUTTED_DOCUMENTS_FOR_PARAMETER_DOCUMENTS_WIDGET:
      return {
        ...state,
        lastSubmittedDocuments: action.payload,
      };
    case TRY_GET_CHARACTERISTIC_HISTORY:
      return {
        ...state,
        characteristicHistoryLoading: true,
        characteristicHistory: initialState.characteristicHistory,
      };
    case GET_CHARACTERISTIC_HISTORY_SUCCESS:
      return {
        ...state,
        characteristicHistoryLoading: initialState.characteristicHistoryLoading,
        characteristicHistory: action.payload,
      };
    case SKIP_LOADING_CHARACTERISTIC_HISTORY:
    case GET_CHARACTERISTIC_HISTORY_ERROR:
      return {
        ...state,
        characteristicHistoryLoading: initialState.characteristicHistoryLoading,
        characteristicHistory: initialState.characteristicHistory,
      };
    case LOAD_CRITERIA_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        stepSampleData: action.payload.sampleData ?? [],
        instanceCharacteristicSampleData: Object.fromEntries(
          action.payload.instanceProgress?.stepData
            .flatMap((sd) => sd.charData)
            .map((csd) => [csd.characteristicId, csd]) ?? [],
        ),
        processInstance: action.payload.instance && {
          ...action.payload.instance,
          status: action.payload.instanceProgress.instanceStatus,
          sync: action.payload.instanceProgress.sync,
          diagramInstance: action.payload.instance.diagramInstance && {
            ...action.payload.instance.diagramInstance,
            processInstanceData: {
              ...action.payload.instance.diagramInstance.processInstanceData,
              instanceLock: action.payload.instanceProgress.instanceLock,
            },
          },
        },
        stepId: action.payload.stepId,
        characteristicId: action.payload.characteristicId,
        selectedIndexInSample: action.payload.indexInSample,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        characteristicHistoryLoading: true,
        characteristicHistory: initialState.characteristicHistory,
        selectedCharacteristicSampleData: initialState.selectedCharacteristicSampleData,
        selectedSampleNumber: initialState.selectedSampleNumber,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case FINISH_CRITERIA_UPDATE:
      return {
        ...state,
        processInstanceLoading: false,
      };
    case CLEAN_SEARCH_STATE:
    case NAVIGATED_TO_DASHBOARD:
      return {
        ...initialState,
        myBridges: state.myBridges,
      };
    case RESET_CRITERIA_ON_ROUTE:
    case CLEAR_DASHBOARD_HEADER_SEARCH_CRITERIA:
      return {
        ...state,
        stepSampleData: initialState.stepSampleData,
        processInstance: initialState.processInstance,
        stepId: initialState.stepId,
        characteristicId: initialState.characteristicId,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        selectedSampleNumber: initialState.selectedSampleNumber,
        characteristicHistory: initialState.characteristicHistory,
        selectedCharacteristicSampleData: initialState.selectedCharacteristicSampleData,
        selectedIndexInSample: initialState.selectedIndexInSample,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case TRY_LOAD_STEP_BY_ID:
      return {
        ...state,
        stepId: initialState.stepId,
        characteristicId: initialState.characteristicId,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        characteristicHistory: initialState.characteristicHistory,
        selectedSampleNumber: initialState.selectedSampleNumber,
        characteristicHistoryLoading: initialState.characteristicHistoryLoading,
        selectedCharacteristicSampleData: initialState.selectedCharacteristicSampleData,
        selectedIndexInSample: initialState.selectedIndexInSample,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case CLEAR_SYNC_STATUS_ERROR_SUCCESS:
      return {
        ...state,
        processInstance: {
          ...state.processInstance,
          sync: {
            ...state.processInstance.sync,
            error: null,
            description: null,
          },
        },
      };
    case ZERO_MESSAGE_CHAR_VALUE_SUBMIT_SUCCESS:
      const charValue = action.payload.message.values[0] as CharacteristicValueDT;

      return {
        ...state,
        processInstance: state.processInstance && {
          ...state.processInstance,
          diagramInstance: state.processInstance.diagramInstance && {
            ...state.processInstance.diagramInstance,
            processInstanceData: {
              ...state.processInstance.diagramInstance.processInstanceData,
              instanceLock: {
                executor: action.payload.currentUser,
                locked: true,
              },
            },
          },
        },
      };
    case SELECT_NEXT_CHARACTERISTIC:
      const nextCharStep = state.processInstance.diagramInstance.steps.find(({ id }) => id === state.stepId);
      const currentNCharIndex = nextCharStep.characteristics.findIndex(({ id }) => id === state.characteristicId);
      const nextCharId = nextCharStep.characteristics[currentNCharIndex + 1]?.id ?? nextCharStep.characteristics[0]?.id;

      return {
        ...state,
        characteristicId: nextCharId,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        characteristicHistory: initialState.characteristicHistory,
        characteristicHistoryLoading: true,
        selectedCharacteristicSampleData: initialState.selectedCharacteristicSampleData,
        selectedIndexInSample: initialState.selectedIndexInSample,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };

    case SELECT_PREVIOUS_CHARACTERISTIC:
      const prevCharStep = state.processInstance.diagramInstance.steps.find(({ id }) => id === state.stepId);
      const currentCharIndex = prevCharStep.characteristics.findIndex(({ id }) => id === state.characteristicId);
      const prevCharId =
        prevCharStep.characteristics[currentCharIndex - 1]?.id ??
        prevCharStep.characteristics[prevCharStep.characteristics.length - 1]?.id;

      return {
        ...state,
        characteristicId: prevCharId,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        characteristicHistory: initialState.characteristicHistory,
        characteristicHistoryLoading: true,
        selectedCharacteristicSampleData: initialState.selectedCharacteristicSampleData,
        selectedIndexInSample: initialState.selectedIndexInSample,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case LOAD_STEP_BY_ID_ERROR:
      return {
        ...state,
        stepId: initialState.stepId,
        characteristicId: initialState.characteristicId,
        selectedIndexInSample: initialState.selectedIndexInSample,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case SEND_STEP_FINISH_MESSAGE_SUCCESS:
      return {
        ...state,
        processInstance: {
          ...state.processInstance,
          diagramInstance: {
            ...state.processInstance.diagramInstance,
            steps: state.processInstance.diagramInstance.steps.map((step) => {
              return step.id === action.payload.stepId
                ? {
                    ...step,
                    status: StepStatus.DONE,
                  }
                : step;
            }),
          },
        },
      };
    case TRY_SUBMIT_CHAR_VALUE_ZERO_MESSAGE:
      return {
        ...state,
        valueMessageSubmitting: true,
      };
    case RECEIVED_CHAR_HISTORY_ENTRY_SAME_CHAR:
    case UPDATED_CHARACTERISTIC_HISTORY:
    case ZERO_MESSAGE_CHAR_VALUE_CHAR_IS_SAME:
      return {
        ...state,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case SEND_NEXT_SAMPLE_MESSAGE_SUCCESS:
      return {
        ...state,
        selectedSampleNumber: initialState.selectedSampleNumber,
        selectedIndexInSample: initialState.selectedIndexInSample,
        characteristicHistory: initialState.characteristicHistory,
        stepSampleData: [...state.stepSampleData, action.payload.sample],
        selectedCharacteristicSampleData:
          state.characteristicId != null
            ? [
                ...state.selectedCharacteristicSampleData,
                action.payload.charData.find(({ characteristicId }) => characteristicId === state.characteristicId),
              ]
            : state.selectedCharacteristicSampleData,
        instanceCharacteristicSampleData: Object.fromEntries(
          action.payload.charData.map((cd) => [cd.characteristicId, cd]),
        ),
      };
    case RECEIVED_SOCKET_PROGRESS:
      const instanceCharData = action.payload.stepData.flatMap((sd) => sd.charData).flatMap((cd) => cd);
      const charData = instanceCharData.find(({ characteristicId }) => characteristicId === state.characteristicId);
      const sampleDataIndex = state.selectedCharacteristicSampleData.findIndex(
        (sd) => sd?.sampleNumber === charData?.sampleNumber,
      );

      let newSelectedCharacteristicSampleData = state.selectedCharacteristicSampleData;

      if (charData != null) {
        if (sampleDataIndex !== -1) {
          newSelectedCharacteristicSampleData = [
            ...state.selectedCharacteristicSampleData.slice(0, sampleDataIndex),
            charData,
            ...state.selectedCharacteristicSampleData.slice(sampleDataIndex + 1),
          ];
        } else {
          newSelectedCharacteristicSampleData = [...state.selectedCharacteristicSampleData, charData];
        }
      }

      const isSameSampleData =
        (state.selectedSampleNumber ?? Math.max(...state.stepSampleData.map(({ sampleNumber }) => sampleNumber))) ===
        charData?.sampleNumber;

      let stepSampleData = state.stepSampleData;
      const newSampleData = action.payload.stepData.find(({ stepId }) => stepId === state.stepId)?.sample;

      if (newSampleData != null) {
        const containsSampleData = stepSampleData.some(
          ({ sampleNumber }) => sampleNumber === newSampleData?.sampleNumber,
        );

        if (containsSampleData) {
          stepSampleData = state.stepSampleData.map((sd) =>
            sd.sampleNumber === newSampleData?.sampleNumber ? newSampleData : sd,
          );
        }
        {
          stepSampleData = stepSampleData.concat(newSampleData);
        }
      }

      return {
        ...state,
        stepSampleData,
        instanceCharacteristicSampleData: isSameSampleData
          ? Object.fromEntries(
              action.payload.stepData.flatMap((sd) => sd.charData).map((csd) => [csd.characteristicId, csd]),
            )
          : state.instanceCharacteristicSampleData,
        selectedCharacteristicSampleData: newSelectedCharacteristicSampleData,
        processInstance: {
          ...state.processInstance,
          status: action.payload.instanceStatus,
          sync: action.payload.sync,
          diagramInstance: state.processInstance.diagramInstance && {
            ...state.processInstance.diagramInstance,
            processInstanceData: {
              ...state.processInstance.diagramInstance.processInstanceData,
              instanceLock: action.payload.instanceLock,
            },
          },
        },
      };
    case RELEASE_PROCESS_INSTANCE_LOCK_SUCCESS:
      if (action.payload.instanceId !== state.processInstance?.id) {
        return { ...state };
      }
      return {
        ...state,
        processInstance: state.processInstance && {
          ...state.processInstance,
          diagramInstance: state.processInstance.diagramInstance && {
            ...state.processInstance.diagramInstance,
            processInstanceData: {
              ...state.processInstance.diagramInstance.processInstanceData,
              instanceLock: {
                executor: null,
                locked: false,
              },
            },
          },
        },
      };
    case UNLOCK_PROCESS_INSTANCE_SUCCESS:
      if (action.payload.instanceId !== state.processInstance?.id) {
        return { ...state };
      }
      return {
        ...state,
        processInstance: state.processInstance && {
          ...state.processInstance,
          diagramInstance: state.processInstance.diagramInstance && {
            ...state.processInstance.diagramInstance,
            processInstanceData: {
              ...state.processInstance.diagramInstance.processInstanceData,
              instanceLock: {
                executor: action.payload.currentUser,
                locked: true,
              },
            },
          },
        },
      };
    case INSTANCE_IS_ALREADY_LOCKED_BY_ANOTHER_USER:
    case ZERO_MESSAGE_CHAR_VALUE_SUBMIT_ERROR:
      return {
        ...state,
        valueMessageSubmitting: false,
      };
    case NO_NEXT_STEP_TO_LOAD:
      return {
        ...state,
        stepId: initialState.stepId,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case LOAD_NEXT_STEP_SUCCESS:
    case LOAD_STEP_BY_ID_SUCCESS:
      return {
        ...state,
        stepId: action.payload?.id,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case NO_STEP_ID_TO_LOAD:
    case STEP_FOR_LOAD_NOT_IN_DIAGRAM:
      return {
        ...state,
        stepId: initialState.stepId,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case SELECT_LOADED_STEP_CHARACTERISTIC_SUCCESS:
      return {
        ...state,
        characteristicId: action.payload?.id,
        characteristicHistoryLoading: true,
        characteristicHistory: initialState.characteristicHistory,
        selectedCharacteristicSampleData: initialState.selectedCharacteristicSampleData,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case ZERO_MESSAGE_CHAR_VALUE_ALL_VALUES_ENTERED:
    case UPDATE_EXCEPTION_ALL_VALUES_ENTERED:
      return {
        ...state,
        characteristicId: initialState.characteristicId,
        characteristicHistoryLoading: initialState.characteristicHistoryLoading,
        characteristicHistory: initialState.characteristicHistory,
        selectedCharacteristicSampleData: initialState.selectedCharacteristicSampleData,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case RECIEVED_CHARACTERISTIC_HISTORY_ENTRY:
      return {
        ...state,
        characteristicHistory: [
          ...state.characteristicHistory.filter(
            (chEntry) =>
              chEntry.sampleNumber !== action.payload.sampleNumber ||
              chEntry.indexInSample !== action.payload.indexInSample,
          ),
          action.payload,
        ].sort((e1, e2) => {
          if (e1.sampleNumber !== e2.sampleNumber) return e1.sampleNumber < e2.sampleNumber ? 1 : -1;
          return e1.indexInSample < e2.indexInSample ? 1 : -1;
        }),
      };
    case SELECT_FOLLOWING_CHARACTERISTIC:
      return {
        ...state,
        characteristicId: action.payload?.id,
        characteristicHistoryLoading: true,
        characteristicHistory: initialState.characteristicHistory,
        selectedCharacteristicSampleData: initialState.selectedCharacteristicSampleData,
        lastSubmittedDocuments: initialState.lastSubmittedDocuments,
        valueMessageSubmitting: initialState.valueMessageSubmitting,
      };
    case UPDATE_CHARACTERISTIC_DS_SUCCESS:
      return {
        ...state,
        processInstance: {
          ...state.processInstance,
          diagramInstance: {
            ...state.processInstance.diagramInstance,
            steps: state.processInstance.diagramInstance.steps.map((step) => ({
              ...step,
              characteristics: step?.characteristics.map((char) =>
                char.id === action.payload.characteristicId
                  ? {
                      ...char,
                      metadata: {
                        ...(char?.metadata ?? new InspectionCharacteristicMetadata()),
                        characteristicInspectionEquipments: action.payload.ie,
                      },
                    }
                  : char,
              ),
            })),
          },
        },
      };
    case UPDATE_ACTION_CATALOG_FOR_CHARACTERISTIC_IN_SAMPLE_SUCCESS:
    default:
      return state;
  }
}

export const getSearchProcessInstance = (state: State) => state.processInstance;

export const getProcessInstanceLoading = (state: State) => state.processInstanceLoading;

export const getSearchStepId = (state: State) => state.stepId;

export const getSearchCharacteristicId = (state: State) => state.characteristicId;

export const getLastSubmittedDocuments = (state: State) => state.lastSubmittedDocuments;

export const getSearchCharacteristicHistory = (state: State) => state.characteristicHistory;

export const getSearchCharacteristicHistoryLoading = (state: State) => state.characteristicHistoryLoading;

export const getValueMessageSubmitting = (state: State) => state.valueMessageSubmitting;

export const getSearchIndexInSampleSelected = (state: State) => state.selectedIndexInSample;

export const getMyBridges = (state: State) => state.myBridges;

export const getSelectedCharacteristicSampleData = (state: State) => state.selectedCharacteristicSampleData;

export const getInstanceCharacteristicSampleData = (state: State) => state.instanceCharacteristicSampleData;

export const getSelectedSampleNumber = (state: State) => state.selectedSampleNumber;

export const getSampleData = (state: State) => state.stepSampleData;
